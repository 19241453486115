import React from "react"

import colours from "../../../constants/colours"
import cx from "classnames";
import styles from "./styles.module.scss"

const RightArrow = ({
  className,
  fill,
}) => {
  let fillColour;

  switch(fill) {
    case 'pink':
      fillColour = colours.pink;
      break;
    case 'purple':
      fillColour = colours.purpleOveprint80;
      break;
    case 'white':
      fillColour = colours.white;
      break;
    default:
      fillColour = colours.pink;
      break;
  }

  return (
    <svg
      className={cx(styles.messageBlockArrow, className && className)}
      version="1.1"
      viewBox="0 0 60 53"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polygon fill={fillColour} points="-0.08,30.86 43.89,30.86 28.1,46.65 34.02,52.57 54,32.59 54,32.6 59.92,26.67 59.92,26.67 59.92,26.67 54,20.75 54,20.75 34.02,0.77 28.1,6.69 43.89,22.48 -0.08,22.48 "/>
    </svg>
  )
}

export default RightArrow;
