import React from "react"

import cx from "classnames"
import styles from "./styles.module.scss"

const DualQuad = ({
  className,
  children
}) => (
  <div className={cx(styles.dualQuad, className && className)}>
    { children }
  </div>
)

export default DualQuad