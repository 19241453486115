import React from "react"
import HubspotForm from "react-hubspot-form"

import cx from "classnames"
import styles from "./styles.module.scss"
import hubspotcss from "./hubspot.css"

const RequestDemo = () => (
  <div className={cx("bg-pink", styles.requestDemo)}>
    <div className="container">
      <h3>Request a demo</h3>
      <p>Leave us your email and we’ll be in touch to arrange a product demo.</p>
      <HubspotForm
        portalId='4432335'
        formId='82095eb2-5a51-4f3a-ab06-770677011764'
        onSubmit={() => console.log('Submit!')}
        onReady={() => console.log('Form ready!')}
        loading={<div>Loading...</div>}
        css={hubspotcss}
        cssClass="requestDemo"
      />
    </div>
  </div>
)

export default RequestDemo
