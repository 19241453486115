import React from "react"

import cx from "classnames"
import styles from "./styles.module.scss"

const RightDualComponent = ({
  rightComponent,
  className,
  theme,
  middleSplit,
  children
}) => (
  <div
    className={
      cx(styles.rightImageArray, theme && styles[theme], className && className, middleSplit ? styles.middleSplit : styles.offSplit)
    }
  >
    <div className={styles.rightImageArrayItems}>
      {rightComponent}
    </div>
    <div className={styles.rightImageArrayContent}>
      {children}
    </div>
  </div>
)

export default RightDualComponent
