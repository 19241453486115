import DualSplit from "./DualSplit"
import DualQuad from "./DualQuad"
import DualImageBlock from "./DualImageBlock"
import RightDualComponent from "./RightDualComponent"
import DualComponentBlock from "./DualComponentBlock"

export {
  DualSplit,
  DualQuad,
  DualImageBlock,
  RightDualComponent,
  DualComponentBlock
}
