import React from "react"

import cx from "classnames"
import styles from "./styles.module.scss"

const DualSplit = ({
  className,
  children
}) => (
  <div className={cx(styles.dualSplit, className && className)}>
    { children }
  </div>
);

export default DualSplit
