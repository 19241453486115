import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/Seo"
import OurClients from "../../components/OurClients"
import chatBubble from "./images/default.svg"
import packageImg from "./images/package.png"
import codingImg from "./images/coding.png"
import menCodingImg from "./images/mencoding.png"
import birdseyecoding from "./images/birdseyecoding.png"
import wallsitting from "./images/wallsitting.png"
import salesImg from "./images/sales.png"
import  {
  PrimaryHero,
  HeroCopyBlock
} from "../../components/Heros"
import {
  DualQuad,
  DualImageBlock,
  RightDualComponent
} from "../../components/Grid"
import Spacer from "../../components/Spacer"
import Button from "../../components/Button"
import { RightArrow } from "../../components/Icons"
import RequestDemo from "../../components/RequestDemo"

import cx from "classnames"
import styles from "./styles.module.scss"

const KeepingUpWithContactImages = () => (
  <div className={styles.keepUpContactImages}>
    <div className={styles.menCoding}>
      <img src={menCodingImg} alt="men coding" />
    </div>
    <div className={styles.birdseyecoding}>
      <img src={birdseyecoding} alt="coding from above" />
    </div>
    <div className={styles.salesImg}>
      <img src={salesImg} alt="sales" />
    </div>
  </div>
)

const EcommerceBlock = () => (
  <div className={styles.ecommerceBlock}>
    <div className={styles.ecommerceBlockStat}>
      <h3>in 2019 this state had 80% more</h3>
    </div>
    <img src={wallsitting} alt="men coding" />
  </div>
)

const Discover = () => (
  <Layout>
    <Seo title="Sorted | Personas" keywords={[`Personas`]} />
    <PrimaryHero theme="pink">
      <HeroCopyBlock title={["Lorem ipsum", "dolor sit amet."]}>
        <p>Refreshingly agile and data-driven software for carrier management and delivery tracking. Powering checkouts, warehouses and shipping, through partnerships with some of the biggest global carriers and customer-obsessed retailers.</p>
      </HeroCopyBlock>
    </PrimaryHero>
    <div className={cx('bg-light', styles.messageBlockHolder)}>
      <div className="container">
        <DualQuad>
          <a href="#" className={styles.messageBlock}>
            <img src={chatBubble} className={styles.messageBlockBubble} alt="message icon"/>
            <h4>Easing eCommerce</h4>
            <a className="link-pink" href="https://www.test.com">Learn more <RightArrow className={styles.arrowRight} /></a>
          </a>
          <a href="#" className={styles.messageBlock}>
            <img src={chatBubble} className={styles.messageBlockBubble} alt="message icon"/>
            <h4>Amazonizing the warehouse</h4>
            <a className="link-pink" href="https://www.test.com">Learn more <RightArrow className={styles.arrowRight} /></a>
          </a>
          <a href="#" className={styles.messageBlock}>
            <img src={chatBubble} className={styles.messageBlockBubble} alt="message icon"/>
            <h4>Keeping up with the contact centres</h4>
            <a className="link-pink" href="https://www.test.com">Learn more <RightArrow className={styles.arrowRight} /></a>
          </a>
          <a href="#" className={styles.messageBlock}>
            <img src={chatBubble} className={styles.messageBlockBubble} alt="message icon"/>
            <h4>The IT architecture of delivery ops</h4>
            <a className="link-pink" href="https://www.test.com">Learn more <RightArrow className={styles.arrowRight} /></a>
          </a>
        </DualQuad>
      </div>
    </div>

    <RightDualComponent
      rightComponent={<EcommerceBlock />}
      middleSplit
    >
      <div className={styles.easingecommerce}>
        <h3>Easing eCommerce</h3>
        <p>When customers feel out of the loop, costly WISMO calls go up, NPS goes down and bad reviews go everywhere. Between the customer clicking ‘buy’ and the parcel turning up at the door, there’s a significant communication black hole – or, even worse, tracking is managed by clunky third-parties. Own your own branded comms, rather than handing over to carriers for delivery updates.</p>
        <ul className="styled">
          <li>Choose which significant events you’d like to hear about.</li>
          <li>REACT APIs can easily embed tracking updates in your website, livechat, email, SMS or app.</li>
          <li>Clever prediction algorithms give you advanced warning of issues.</li>
        </ul>
        <a className="link-pink" href="https://www.test.com">Learn more about Sorted.PRO <RightArrow /></a>
        <a className="link-pink" href="https://www.test.com">Learn more about Sorted.Hero <RightArrow /></a>
      </div>
    </RightDualComponent>

    <DualImageBlock img={packageImg} theme="beige" maxHeight={700}>
      <h3>Amazonizing the warehouse</h3>
      <p>Quisque quis augue lobortis, interdum eros et, pulvinar nulla. Curabitur accumsan enim ac sem hendrerit, ut luctus leo auctor. Fusce fringilla nunc pharetra, posuere turpis vel, vulputate risus.</p>
      <ul className="styled">
        <li>Morbi tempus, neque eget volutpat bibendum</li>
        <li>Fusce fringilla nunc pharetra, posuere turpis vel, vulputate risus. Cras facilisis matti</li>
        <li>Eros justo mattis massa, sit amet gravida mag na enim non ligula</li>
        <li>Onec vestibulum, orci eu porttitor sagittis, nunc velit sagittis nisi</li>
        <li>Cras felis tortor, feugiat non nulla a, dignissim aliquam metus</li>
      </ul>
      <p>Quisque quis augue lobortis, interdum eros et, pulvinar nulla. Curabitur accum enim ac sem hendrerit, ut luctus leo auctor.</p>
      <a className="link-pink" href="https://www.test.com">Learn more about Sorted.PRO <RightArrow /></a>
    </DualImageBlock>

    <Spacer size="large" sides="topbottom">
      <div className="container-large-fluid">
        <RightDualComponent
          rightComponent={<KeepingUpWithContactImages />}
          theme="pink"
        >
          <h3>The IT architecture of delivery ops</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer augue velit, aliquam a volutpat vitae, vestibulum vel ligula. Donec vitae purus dignissim, dignissim augue quis, molestie sem.</p>
          <ul className="styled">
            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer augue velit, aliquam a volutpat vitae, vestibulum vel ligula.</li>
            <li>Fusce tincidunt magna dui, efficitur condimentum dolor consequat id.</li>
            <li>Vestibulum condimentum nulla sed dapibus finibus. Integer pharetra risus eu iaculis.</li>
          </ul>
          <Button theme="purple"><a href="https://www.test.com">Learn more</a></Button>
        </RightDualComponent>
      </div>
    </Spacer>

    <DualImageBlock img={codingImg} theme="lightpurple" maxHeight={700}>
      <h3>The IT architecture of delivery ops</h3>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer augue velit, aliquam a volutpat vitae, vestibulum vel ligula. Donec vitae purus dignissim, dignissim augue quis, molestie sem.</p>
      <ul className="styled">
        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer augue velit, aliquam a volutpat vitae, vestibulum vel ligula. Donec vitae purus dignissim, dignissim augue quis, molestie sem.</li>
        <li>Fusce tincidunt magna dui, efficitur condimentum dolor consequat id. Nulla luctus cursus magna vel aliquet.</li>
        <li>Vestibulum condimentum nulla sed dapibus finibus. Integer pharetra risus eu iaculis. Suspendisse lobortis leo in pharetra laoreet</li>
      </ul>
      <Button theme="purple"><a href="https://www.test.com">Learn more about Sorted.PRO</a></Button>
    </DualImageBlock>

    <div className="container">
      <Spacer size="large" sides="topbottom">
        <h3 className="text-pink text-center">Our clients.</h3>
        <p className="text-center">Here are just some of the brands joining us on the retail delivery revolution.</p>
        <OurClients />
      </Spacer>
    </div>

    <RequestDemo />

  </Layout>
)

export default Discover
