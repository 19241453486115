import React from "react"

import cx from "classnames"
import styles from "./styles.module.scss"

const DualComponentBlock = ({
  leftComponent,
  rightComponent,
  splitTablet,
  className
}) => (
  <div className={cx(styles.dualComponentBlock, splitTablet && styles.splitTablet, className && className)}>
    {leftComponent}
    {rightComponent}
  </div>
)

export default DualComponentBlock
