import React from "react";

import cx from "classnames"
import styles from "./styles.module.scss"

const DualImageBlock = ({
  img,
  children,
  theme,
  maxHeight,
  reverse,
}) => (
  <div className={cx(styles.DualImageBlock, reverse && styles.reverse, theme && styles[theme])}>
    {!reverse && (
      <>
        <div className={styles.DualImageBlockImgHolder} style={maxHeight && { maxHeight: `${maxHeight}px` }}>
          <img className={styles.DualImageBlockImg} src={img} alt="large header section"/>
        </div>
        <div className={styles.DualImageBlockContent}>
          {children}
        </div>
      </>
    )}
    {reverse && (
      <>
        <div className={cx(styles.DualImageBlockContent, styles.DualImageBlockContentRight)}>
          {children}
        </div>
        <div className={styles.DualImageBlockImgHolder} style={maxHeight && { maxHeight: `${maxHeight}px` }}>
          <img className={styles.DualImageBlockImg} src={img} alt="large header section"/>
        </div>
      </>
    )}
  </div>
)

export default DualImageBlock
